import { withPrefix } from 'gatsby'
import { countryPrefix, wpUrl } from './env'

const isHttp = (link: string) =>
  link.startsWith('http:') || link.startsWith('https:')

export const sanitiseLink = (link?: string, isInnerLink = false) => {
  if (!link) return '#'

  if (isInnerLink) {
    if (isHttp(link)) return withPrefix(link)

    return link
  }

  if (countryPrefix !== '' && link.startsWith(countryPrefix))
    return link.replace(countryPrefix, '')

  if (link.startsWith(wpUrl)) return link.replace(wpUrl, '')

  return link
}
