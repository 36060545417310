import { useState, useEffect } from 'react'

/**
 * @param {number} breakpoint
 */
export const useIsViewportBelow = breakpoint => {
  const getIsViewportBelow = () => window.innerWidth < breakpoint

  const [isViewportBelow, setIsViewportBelow] = useState(getIsViewportBelow())

  const handleWindowResize = () => setIsViewportBelow(getIsViewportBelow())

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize)
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  return isViewportBelow
}
