export const getLocaleFromCountry = country => {
  switch (country) {
    case 'GB':
      return 'en-GB'
    case 'IT':
      return 'it-IT'
    default:
      return 'en-GB'
  }
}
