import { AppearanceVariant, ElementLevel } from './types'

export function getStyleNameFromAppearAs(appearAs: AppearanceVariant) {
  switch (appearAs) {
    case 11:
    case 'editorialTitle1':
      return 'editorialTitle1'

    case 12:
    case 'editorialTitle2':
      return 'editorialTitle2'

    case '1':
    case 1:
    case 'title1':
      return 'title1'

    case 2:
    case '2':
    case 'title2':
      return 'title2'

    case 15:
    case 'title3':
      return 'title3'

    case 16:
    case 'title4':
      return 'title4'

    case '3':
    case 3:
    case 'title5':
      return 'title5'

    case '4':
    case 4:
    case 'title6':
      return 'title6'

    case '5':
    case 5:
    case 'title7':
      return 'title7'

    case '6':
    case 6:
    case 'title8':
      return 'title8'
  }
}

export function getElFromLevel(level: ElementLevel) {
  switch (level) {
    case '1':
    case 1:
      return 'h1'

    case '2':
    case 2:
      return 'h2'

    case '3':
    case 3:
      return 'h3'

    case '4':
    case 4:
      return 'h4'

    case '5':
    case 5:
      return 'h5'

    case '6':
    case 6:
      return 'h6'
  }
}
