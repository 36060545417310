export const PRIMARY_HEADING_VARIANTS = [
  11,
  'editorialTitle1',
  12,
  'editorialTitle2',
  '1',
  1,
  'title1',
  '2',
  2,
  'title2',
  '3',
  3,
  15,
  'title3',
  16,
]
