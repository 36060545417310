import { graphql, withPrefix } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

import { marginBreakpoints, hideBlockAt } from '../../../utils/commonAttrs'
import { getIconSrc } from '../../../utils/env'

const ImageTag = styled.img`
  ${props => marginBreakpoints(props, 20)};
  ${props => hideBlockAt(props)}
`

export default function Icon({ className, icon, marginBottom, hiddenAt }) {
  return (
    <ImageTag
      className={className}
      marginBottom={marginBottom}
      hiddenAt={hiddenAt}
      src={withPrefix(getIconSrc(icon))}
      alt="icon"
      loading="lazy"
    />
  )
}

export const query = graphql`
  fragment iconFields on WPGraphQL_MfblocksIconBlock {
    name
    attributes {
      icon
      marginBottom
      hiddenAt
    }
  }
`
