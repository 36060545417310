import { withPrefix } from 'gatsby'
import ReactHtmlParser from 'react-html-parser'

import { sanitiseLink } from './sanitiseLink'

function transform(node: Record<string, any>) {
  if (node.type === 'tag' && node.name === 'a')
    node.attribs.href = node.attribs.href.startsWith('mailto')
      ? node.attribs.href
      : withPrefix(sanitiseLink(node.attribs.href))
}

export default function parser(html: string) {
  return ReactHtmlParser(html, { transform })
}
