const gridTheme = {
  container: {
    padding: 16,
    maxWidth: {
      // defaults below
      xxl: 1176,
      xl: 1176,
      lg: 972,
      md: 732,
      sm: 540,
      xs: 540,
    },
  },
  row: {
    padding: 16,
  },
  col: {
    padding: 16,
  },
  breakpoints: {
    xxl: 1440,
    xl: 1200,
    lg: 992,
    md: 768,
    sm: 576,
    xs: 0,
  },
} as const

export default gridTheme
