import React, { FC, PropsWithChildren, ReactChild, ReactElement, ReactFragment, ReactNode } from 'react'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import { useTheme } from 'styled-components'

import parser from '../../../../utils/htmlParser'
import AnimateOnScroll from '../../../common/AnimateOnScroll'
import { normalizeHexColourCode } from '../../../../libs/colours'
import { HeadingTag } from './Heading.styles'
import { getElFromLevel, getStyleNameFromAppearAs } from './utils'
import { AppearanceVariant, ElementLevel } from './types'
import { BaseBlockProps } from '../../../../types/BaseBlockProps'

export type Props = {
  level: ElementLevel
  appearAs: AppearanceVariant
  customTextColor?: string
  style?: Partial<CSSStyleDeclaration>
  childBlock: boolean
  textAlign?: string
  animateOnScroll?: boolean
  animationType?: string
  animationDelay?: string
  animationEasing?: string
  withBorder?: boolean
} & BaseBlockProps

export const Heading: FC<PropsWithChildren<Props>> = ({
  id,
  level = 2,
  appearAs,
  customTextColor,
  marginBottom,
  children,
  style = {},
  hiddenAt,
  childBlock,
  textAlign = 'inherit',
  animateOnScroll = false,
  animationType,
  animationDelay = 0,
  animationEasing = 'linear',
  withBorder = false,
}) => {
  const theme = useTheme()

  const inverted =
    customTextColor &&
    normalizeHexColourCode(theme.tokens.text.title.reversed) ===
      normalizeHexColourCode(customTextColor)

  const HeadingElement = (
    <HeadingTag
      id={id}
      el={getElFromLevel(level)}
      styleName={getStyleNameFromAppearAs(appearAs)}
      variant={inverted ? 'inverted' : undefined}
      marginBottom={marginBottom}
      hiddenAt={hiddenAt}
      appearAs={appearAs}
      style={{ ...(style as CSSStyleDeclaration), textAlign }}
      withBorder={withBorder}
    >
      {parser(children as string)}
    </HeadingTag>
  )

  const Element = animateOnScroll ? (
    <AnimateOnScroll
      animationType={animationType}
      animationDelay={animationDelay}
      animationEasing={animationEasing}
    >
      {HeadingElement}
    </AnimateOnScroll>
  ) : (
    HeadingElement
  )

  if (childBlock) {
    return Element
  }

  return (
    <Container>
      <Row>
        <Col col={12}>{Element}</Col>
      </Row>
    </Container>
  )
}
