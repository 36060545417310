import { css } from 'styled-components'

import gridTheme from '../gridTheme'

// NOTE: The usage of `prettier-ignore` in this file, is due to the fact that,
//  by formatting the file:
//    - The type annotations for `SizeHint` would disappear.
//    - A semicolon would be appended to the end of media queries.

export function fromBreakpoint<
  Breakpoint extends keyof typeof gridTheme['breakpoints'],
  // prettier-ignore
  SizeHint extends `${typeof gridTheme['breakpoints'][Breakpoint]}px`
>(bp: Breakpoint) {
  // prettier-ignore
  return css`
    @media screen and (min-width: ${gridTheme.breakpoints[bp]}px)
  `
}

export function untilBreakpoint<
  Breakpoint extends keyof typeof gridTheme['breakpoints'],
  // prettier-ignore
  SizeHint extends `${typeof gridTheme['breakpoints'][Breakpoint]}px`
>(bp: Breakpoint) {
  // prettier-ignore
  return css`
    @media screen and (max-width: ${gridTheme.breakpoints[bp]}px)
  `
}

export function betweenBreakpoints<
  Breakpoint1 extends keyof typeof gridTheme['breakpoints'],
  Breakpoint2 extends keyof typeof gridTheme['breakpoints'],
  // prettier-ignore
  SizeHint extends `${typeof gridTheme['breakpoints'][Breakpoint1]}px ~ ${typeof gridTheme['breakpoints'][Breakpoint2]}px`
>(bp1: Breakpoint1, bp2: Breakpoint2) {
  // prettier-ignore
  return css`
    @media screen and (min-width: ${gridTheme.breakpoints[bp1]}px) and (max-width: ${gridTheme.breakpoints[bp2]}px)
  `
}
