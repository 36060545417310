import type { DefaultTheme } from 'styled-components'

/**
 * #### Tokens:
 * ```
 * 2xs -> 4px
 * xs -> 8px
 * sm -> 12px
 * md -> 16px
 * lg -> 24px
 * xl -> 32px
 * 2xl -> 40px
 * 3xl -> 48px
 * 4xl -> 64px
 * 5xl -> 80px
 * ```
 */
export function spacing<Token extends keyof DefaultTheme['tokens']['spacing']>(
  value: Token
) {
  return ({ theme }: { theme: DefaultTheme }) => theme.tokens.spacing[value]
}