import { DefaultTheme } from 'styled-components'

import { Theme, Themes } from 'mf-ui-components'

/**
 * Retrieves a theme from the `mf-ui-components` package.
 * The theme id to use to retrieve the corresponding theme is passed directly,
 * but it can be overridden by setting the `THEME_ID` environment variable.
 *
 * @returns {Themes} The theme to use.
 */
export function getTheme(themeId: Theme['id'] = 'mf-light'): DefaultTheme {
  switch (process.env.THEME_ID || themeId) {
    case 'mf-2023-light':
    default:
      return Themes.mf2023Light

    case 'mf-light':
      return Themes.mfLight
  }
}
