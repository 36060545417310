export const isDevEnv = process.env.NODE_ENV === 'development'
export const isBrowserEnv = process.env.BUILD_STAGE === 'build-javascript'
export const isSSREnv = process.env.BUILD_STAGE === 'build-html'
export const wpUrl = String(process.env.GATSBY_WP_URL)
export const siteUrl = String(process.env.GATSBY_SITE_URL)
export const countryPrefix = String(process.env.GATSBY_PATH_PREFIX)
export const locale = `${process.env.GATSBY_LANG}-${process.env.GATSBY_COUNTRY}`
export const getIconSrc = (link: string) => `/icons/${link}`
export const currency = String(process.env.GATSBY_CURRENCY)
export const platform: 'IT' | 'GB' = (String(process.env.GATSBY_COUNTRY) ||
  'GB') as 'IT' | 'GB'
