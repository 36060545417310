import rawlang, * as l10n from '../l10n'

const lang = rawlang as 'it' | 'en'

export function l10nCopy(
  copyKey: string,
  values?: Record<string, string | number>
): string {
  const target = copyKey
    .split('.')
    .reduce((acc: Record<string, any>, key: string) => {
      if (typeof acc === 'string') {
        return acc
      }

      if (!acc) return ''

      return key in acc ? acc[key] : ''
    }, l10n)

  const translation = target[lang]

  if (!values) return translation

  const valuesKeys = Object.keys(values || {})

  if (valuesKeys.length === 0) return translation

  return valuesKeys.reduce(
    (acc, key) => acc.split(`{${key}}`).join(values[key]),
    translation
  )
}
