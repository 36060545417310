import React, { FC } from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

import parser from '../utils/htmlParser'
import canonicalUrl from '../utils/canonicalUrl'
import { noIndex, noFollow } from '../utils/metaRobots'

type Props = {
  title?: string
  description?: string
  keywords?: string
  canonical?: string
  opengraphTitle?: string
  opengraphDescription?: string
  opengraphImage?: {
    mediaItemUrl?: string
  }
  twitterTitle?: string
  twitterDescription?: string
  twitterImage?: {
    mediaItemUrl?: string
  }
  metaRobotsNoindex?: string
  metaRobotsNofollow?: string
}

export const SEO: FC<Props> = ({
  title,
  description,
  keywords,
  canonical,
  opengraphTitle,
  opengraphDescription,
  opengraphImage,
  twitterTitle,
  twitterDescription,
  twitterImage,
  metaRobotsNoindex,
  metaRobotsNofollow,
}) => {
  const {
    site: {
      siteMetadata: {
        defaultTitle,
        defaultDescription,
        siteUrl,
        facebookUrl,
        twitterUrl,
        youtubeUrl,
        appleItunesApp,
        googlePlayApp,
        androidAppUrl,
      },
    },
    wpgraphql: {
      seo: {
        schema: { companyName, companyLogo },
      },
    },
  } = useStaticQuery(graphql`
    query SEO {
      site {
        siteMetadata {
          defaultTitle: title
          defaultDescription: description
          siteUrl
          facebookUrl
          youtubeUrl
          twitterUrl
          appleItunesApp
          googlePlayApp
          androidAppUrl
        }
      }
      wpgraphql {
        seo {
          schema {
            companyName
            companyLogo {
              mediaItemUrl
              mediaDetails {
                width
                height
              }
            }
          }
        }
      }
    }
  `)

  return (
    <>
      {/* @ts-ignore */}
      <Helmet>
        <title>{parser(title || defaultTitle)}</title>
        <meta name="description" content={description || defaultDescription} />
        {keywords && <meta name="keywords" content={keywords} />}

        <meta
          name="robots"
          content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />

        <meta
          name="robots"
          content={`${noIndex(metaRobotsNoindex)}, ${noFollow(
            metaRobotsNofollow
          )}`}
        />
        <meta
          name="googlebot"
          content={`${noIndex(metaRobotsNoindex)}, ${noFollow(
            metaRobotsNofollow
          )}`}
        />

        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={canonical ? canonicalUrl(canonical) : siteUrl}
        />
        <meta
          property="og:title"
          content={opengraphTitle || title || defaultTitle}
        />
        <meta
          property="og:description"
          content={opengraphDescription || description || defaultDescription}
        />
        <meta
          property="og:image"
          content={
            opengraphImage
              ? opengraphImage.mediaItemUrl
              : companyLogo.mediaItemUrl
          }
        />

        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:title"
          content={twitterTitle || title || defaultTitle}
        />
        <meta
          name="twitter:description"
          content={twitterDescription || description || defaultDescription}
        />
        <meta
          name="twitter:image"
          content={
            twitterImage ? twitterImage.mediaItemUrl : companyLogo.mediaItemUrl
          }
        />

        <meta name="apple-itunes-app" content={appleItunesApp} />
        <meta name="google-play-app" content={googlePlayApp} />
        <meta name="android-app-url" content={androidAppUrl} />

        {canonical && <link rel="canonical" href={canonicalUrl(canonical)} />}

        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "@id": "${siteUrl}/#organization",
              "name": "${companyName}",
              "url": "${siteUrl}",
              "sameAs": [
                "${facebookUrl}",
                "${youtubeUrl}",
                "${twitterUrl}"
              ],
              "logo": {
                "@type": "ImageObject",
                "@id": "${siteUrl}/#logo",
                "url": "${companyLogo.mediaItemUrl}",
                "width": ${companyLogo.mediaDetails.width},
                "height": ${companyLogo.mediaDetails.height},
                "caption": "${companyName}"
              },
              "image": {
                "@id": "${siteUrl}/#logo"
              }
            }
          `}
        </script>
      </Helmet>
    </>
  )
}
