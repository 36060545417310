import styled, { css, StyledProps } from 'styled-components'

import { Text } from 'mf-ui-components'

import { hideBlockAt, marginBreakpoints } from '../../../../utils/commonAttrs'
import { spacing } from '../../../../utils/ui/spacing'
import { PRIMARY_HEADING_VARIANTS } from './data'
import { Props } from './Heading'
import { serifFont } from '../../../../utils/style/serifFont'

type TagProps = {
  hiddenAt: Props['hiddenAt']
  marginBottom: Props['marginBottom']
  appearAs: Props['appearAs']
  el: string
  variant: 'inverted' | undefined
  styleName: string
  withBorder: boolean
  id?: string
  style: StyledProps<HTMLElement>['style']
}

export const HeadingTag = styled(Text)<TagProps>`
  ${props => marginBreakpoints(props)}
  ${props => hideBlockAt(props)}

  
  ${({ appearAs, theme }) =>
    theme.id === 'mf-2023-light' && PRIMARY_HEADING_VARIANTS.includes(appearAs)
      ? serifFont
      : ''}
  
  width: 100%;

  ${({ withBorder }) =>
    withBorder &&
    css`
      position: relative;
      padding-left: ${spacing('md')};

      &:after {
        position: absolute;
        display: block;
        content: '';
        width: 4px;
        top: 0;
        left: 0;
        height: 1.25em;
        background-color: ${({ theme }) =>
          theme.tokens.accent.token.primary.regular};
      }
    `};
`
